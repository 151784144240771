import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledContactForm = styled.div`
  padding-top: ${Rem(52)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(110)};
  }

  ${(props) =>
    props.hasGradient
    && css`
      padding-bottom: 1px;
    `}
`;

export const StyledContactFormSubmit = styled.div`
  position: relative;
  padding-top: ${Rem(30)};
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
  }
`;
