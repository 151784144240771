import React, { useState, useContext } from "react";
import { StyledContactForm, StyledContactFormSubmit } from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import { toast } from "react-toastify";
import { DataContext } from "../../contexts/DataContext";

import {
  StyledForm,
  StyledFormFieldset,
  StyledFormBlock,
  StyledFormInput,
  StyledFormSubmit,
} from "../../commons/Form";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import Intro from "../Intro/Intro";

export default ({
  themeColor, title, bigText, positiveMessages, negativeMessages,
}) => {
  const [formData, setFormData] = useState({
    lang: "it",
    grecaptcharesponse: "token",
  });
  const { labels } = useContext(DataContext);

  return (
    <StyledContactForm hasGradient={themeColor.black} id="contactForm">
      <Intro title={title} bigText={bigText} />
      <StyledGridRow>
        <StyledForm
          id="contact3Bee"
          Validate
          onSubmit={(event) => {
            toast.configure();
            event && event.preventDefault();
            let form_data = new FormData(event.currentTarget);
            form_data.append("name", formData.name);
            form_data.append("tel", formData.tel);
            form_data.append("email", formData.email);
            form_data.append("request", formData.request);
            form_data.append("g-recaptcha-response", "token");
            form_data.append("lang", "IT");
            fetch("https://3bee.link:8989/api/sendRequest", {
              method: "POST",
              body: form_data,
            })
              .then((response) => {
                if (response.status === 200) {
                  toast.success(positiveMessages, {
                    containerId: "Alert",
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  if (typeof window !== "undefined") {
                    window.dataLayer.push({
                      event: "CONTACT",
                      description: "Richiesta di contatto",
                    });
                  }
                } else {
                  toast.error(negativeMessages, {
                    containerId: "Alert",
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  //console.log("Contattaci a info@3bee.it")
                  if (typeof window !== "undefined") {
                    window.dataLayer.push({
                      event: "exception",
                      description: "Errore Contatto CSR",
                      fatal: true,
                    });
                  }
                }

                //this.props.newCaptchaToken();
              })
              .catch(function () {
                toast.error(negativeMessages, {
                  containerId: "Alert",
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                //console.log("Errore con la chiamata")
              });
            event.target.reset();
            return false;
          }}
        >
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-user"
                type="text"
                placeholder={labels.NAME_SURNAME}
                required
                onInput={(e) => {
                  setFormData({
                    ...formData,
                    name: e.currentTarget.value,
                  });
                }}
                isBlack={themeColor.black}
              />

            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-email"
                type="email"
                required
                placeholder={labels.MAIL_ADDRESS}
                onInput={(e) => {
                  setFormData({
                    ...formData,
                    email: e.currentTarget.value,
                  });
                }}
                isBlack={themeColor.black}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-phone"
                type="tel"
                required
                placeholder={labels.PHONE_MOBILE}
                onInput={(e) => {
                  setFormData({
                    ...formData,
                    tel: e.currentTarget.value,
                  });
                }}
                isBlack={themeColor.black}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                as="textarea"
                rows={3}
                id="contacts-request"
                required
                placeholder={labels.REQUEST}
                onInput={(e) => {
                  setFormData({
                    ...formData,
                    request: e.currentTarget.value,
                  });
                }}
                isBlack={themeColor.black}
              />

            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledContactFormSubmit>
            <StyledFormSubmit type="submit">
              {labels.SEND_REQUEST}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledContactFormSubmit>
        </StyledForm>
      </StyledGridRow>
    </StyledContactForm>
  );
};
